if (!Array.from) {
    Array.from = (function () {
        var toStr = Object.prototype.toString;
        var isCallable = function (fn) {
            return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
        };
        var toInteger = function (value) {
            var number = Number(value);
            if (isNaN(number)) { return 0; }
            if (number === 0 || !isFinite(number)) { return number; }
            return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
        };
        var maxSafeInteger = Math.pow(2, 53) - 1;
        var toLength = function (value) {
            var len = toInteger(value);
            return Math.min(Math.max(len, 0), maxSafeInteger);
        };

        // The length property of the from method is 1.
        return function from(arrayLike/*, mapFn, thisArg */) {
            // 1. Let C be the this value.
            var C = this;

            // 2. Let items be ToObject(arrayLike).
            var items = Object(arrayLike);

            // 3. ReturnIfAbrupt(items).
            if (arrayLike == null) {
                throw new TypeError("Array.from requires an array-like object - not null or undefined");
            }

            // 4. If mapfn is undefined, then let mapping be false.
            var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
            var T;
            if (typeof mapFn !== 'undefined') {
                // 5. else
                // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
                if (!isCallable(mapFn)) {
                    throw new TypeError('Array.from: when provided, the second argument must be a function');
                }

                // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
                if (arguments.length > 2) {
                    T = arguments[2];
                }
            }

            // 10. Let lenValue be Get(items, "length").
            // 11. Let len be ToLength(lenValue).
            var len = toLength(items.length);

            // 13. If IsConstructor(C) is true, then
            // 13. a. Let A be the result of calling the [[Construct]] internal method of C with an argument list containing the single item len.
            // 14. a. Else, Let A be ArrayCreate(len).
            var A = isCallable(C) ? Object(new C(len)) : new Array(len);

            // 16. Let k be 0.
            var k = 0;
            // 17. Repeat, while k < len… (also steps a - h)
            var kValue;
            while (k < len) {
                kValue = items[k];
                if (mapFn) {
                    A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
                } else {
                    A[k] = kValue;
                }
                k += 1;
            }
            // 18. Let putStatus be Put(A, "length", len, true).
            A.length = len;
            // 20. Return A.
            return A;
        };
    }());
}
{
    const initShapeLoop = function() {
        Array.from(document.querySelectorAll('.animate-shape')).forEach(function (el) {
            var shapeEl = el.querySelector('path');
            if(!shapeEl || !shapeEl.dataset['animateFrom'] || !shapeEl.dataset['animateTo']){
                return false;
            }
            var duration  = Math.random() * 700 + 1300;
            anime.remove(shapeEl);
            anime({
                targets: shapeEl,
                easing: 'linear',
                d: [{value: shapeEl.dataset['animateFrom'], duration:100}, {value: shapeEl.dataset['animateTo'], duration:duration}],
                loop: true,
                direction: 'alternate'
            });

        });
    };

    const createScrollWatchers = function() {

        var className = "sliding-up";
        var scrollElems = Array.from(document.querySelectorAll("." + className));
        scrollElems.forEach(function(scrollElemToWatch,pos) {
            //const scrollElemToWatch = scrollElems[pos];
            var position = 100;
            if(scrollElemToWatch.dataset.pos){
                position = scrollElemToWatch.dataset.pos;
            }
            var watcher = scrollMonitor.create(scrollElemToWatch,position);

            watcher.enterViewport(function(a,b,c) {

                if(scrollElemToWatch.className.indexOf("-visible") === -1){
                    var delay = 1;

                    if(scrollElemToWatch.dataset.delay){
                        delay = scrollElemToWatch.dataset.delay;
                    }
                    if(scrollElemToWatch.dataset.mdDelay && window.innerWidth > 767){
                        delay = scrollElemToWatch.dataset.mdDelay;
                    }

                    setTimeout(function () {
                        scrollElemToWatch.className = scrollElemToWatch.className.replace(className, className+"-visible") ;
                    }, delay);

                }
            });

            watcher.exitViewport(function() {
                //watcher.isAboveViewport
            });
        });
    };

    const ifElementInView = function(element, pixelsInView){
        try {
            if(!pixelsInView){
                pixelsInView = 0;
            }
            var pageTop = 0;
            var pageBottom = pageTop + window.innerHeight;
            var elementTop = element.getBoundingClientRect().top;
            var elementBottom = elementTop + element.offsetHeight;
            var inView = false;

            if(
                (elementTop + pixelsInView <= pageBottom && elementTop - pixelsInView >= pageTop)
                ||
                (elementBottom - pixelsInView >= pageTop && elementBottom + pixelsInView <= pageBottom)
            ){
                inView = true;
            }

            return inView;
        }catch (e){
            return false;
        }
    };

    const getElementPosInView = function (element) {
        var pageHeight = window.innerHeight;
        var pageCenter = pageHeight/2;
        var elementTop = element.getBoundingClientRect().top;
        var elementCenter = elementTop + (element.offsetHeight/2);

        return (elementCenter - pageCenter) / pageCenter * 100;

    };

    const createCircleBlockAnimationOld = function () {
        var className = "circle-animation-block";
        var scrollElems = Array.from(document.querySelectorAll("." + className));

        var animateFirstItem = function(block){
            var item = block.querySelector('.part_1');
            if(item && item.className.indexOf('finished') === -1){
                var s = 1;
                item.style.width = s+'%';
                item.style.height = s+'%';
                item.style.display = 'block';
                var interval = setInterval(function () {
                    s++;
                    if(s <= 100){
                        item.style.width = s+'%';
                        item.style.height = s+'%';
                    }else{
                        clearInterval(interval);
                        item.className+=" finished";
                        animateSecondItem(block);

                    }
                }, 7)
            }else{
                animateSecondItem(block);
            }
        };

        var animateSecondItem = function (block) {
            var item = block.querySelector('.part_2');
            if(item && item.className.indexOf('finished') === -1){
                var o = 0.00;
                var t_n = 30 * 0.02;
                var t = 80 - t_n;
                item.style.top = t+'%';
                item.style.opacity = o;
                item.style.display = 'block';
                var interval = setInterval(function () {
                    o += 0.02;
                    t -= t_n;
                    if(o <= 1){
                        item.style.top = t+'%';
                        item.style.opacity = o;
                    }else{
                        clearInterval(interval);
                        item.className+=" finished";
                        animateThirdItem(block);
                        if(block === scrollElems[0]){
                            animateBlockItems(scrollElems[1]);
                        }
                    }
                }, 10)
            }else{
                animateThirdItem(block);
            }
        };

        var animateThirdItem = function (block) {
            var item = block.querySelector('.part_3');
            if(item && item.className.indexOf('finished') === -1){
                var text = item.innerText.trim();
                item.innerText = '';
                var l = text.length;
                var c = 1;

                item.style.display = 'block';
                var interval = setInterval(function () {
                    if(c <= l){
                        item.innerText = text.substring(0, c);
                        c++;
                    }else{
                        clearInterval(interval);
                        item.className+=" finished";
                        animateFourthItem(block);
                    }
                }, 30)
            }else{
                animateFourthItem(block);
            }
        };

        var animateFourthItem = function (block) {
            var item = block.querySelector('.part_4');
            if(item && item.className.indexOf('finished') === -1){
                var o = 0.00;
                var t_n = 50 * 0.02;
                var t = 50 - t_n;
                item.style.top = t+'%';
                item.style.opacity = o;
                item.style.display = 'block';
                item.style.position = 'relative';
                var interval = setInterval(function () {
                    o += 0.02;
                    t -= t_n;
                    if(o <= 1){
                        item.style.top = t+'%';
                        item.style.opacity = o;
                    }else{
                        clearInterval(interval);
                        bounceItem(item, t, -40, function () {
                            item.className+=" finished";
                            animateFifthItem(block);
                        });
                    }
                }, 8)
            }else{
                animateFifthItem(block);
            }
        };

        var animateFifthItem = function (block) {
            var item = block.querySelector('.part_5');
            if(item && item.className.indexOf('finished') === -1){
                var text = item.innerText.trim();
                item.innerText = '';
                var l = text.length;
                var c = 1;
                item.style.display = 'block';
                var interval = setInterval(function () {
                    if(c <= l){
                        item.innerText = text.substring(0, c);
                        c++;
                    }else{
                        clearInterval(interval);
                        item.className+=" finished";
                        animateSixthItem(block);
                    }
                }, 30)
            }else{
                animateSixthItem(block);
            }
        };

        var animateSixthItem = function (block) {
            var item = block.querySelector('.part_6');
            if(item && item.className.indexOf('finished') === -1){
                var o = 0.00;
                var t_n = 30 * 0.02;
                var t = 80 - t_n;
                item.style.top = t+'%';
                item.style.opacity = o;
                item.style.display = 'block';
                var interval = setInterval(function () {
                    o += 0.02;
                    t -= t_n;
                    if(o <= 1){
                        item.style.top = t+'%';
                        item.style.opacity = o;
                    }else{
                        clearInterval(interval);
                        item.className+=" finished";
                        animateLine(block);
                    }
                }, 10)
            }else{
                animateLine(block);
            }
        };

        var animateLine = function (block) {
            var line = document.querySelector(block.dataset.line);
            if(!block || !block.dataset.stroke || !line || !line.style.strokeDashoffset || window.innerWidth < 768){
                block.className+=" finished";

                return false;
            }
            var c = parseInt(line.style.strokeDashoffset);
            var pos = parseInt(block.dataset.stroke);
            var interval = setInterval(function () {
                c--;
                if(c >= pos){
                    line.style.strokeDashoffset = c;
                }else{
                    clearInterval(interval);
                    block.className+=" finished";
                    scrollElems.forEach(function(scrollElemToWatch,pos) {
                        if(block === scrollElemToWatch && scrollElems[pos+1]){
                            scrollListeners(scrollElems[pos+1], pos+1);
                        }
                    });

                }
            }, 1);

        };

        var bounceItem = function(item, pos, to, callback){
            var point = to;
            var step = 1;
            var interval = setInterval(function () {
                if(pos === to){
                    step++;
                    if(step % 2 === 0){
                        to = 0;
                    }else{
                        to = point/2;
                    }
                }
                if(step % 2 === 0){
                    pos++;
                }else{
                    pos--;
                }

                if(step >= 5){
                    clearInterval(interval);
                    callback();
                }

                item.style.top = pos+'px';
            }, 2)
        };

        var animateBlockItems = function(block){
            animateFirstItem(block);
        };

        var scrollListeners = function(scrollElemToWatch, pos){
            if (
            	(
            		(scrollElems[pos-1] && scrollElems[pos-1].className.indexOf('finished') !== -1) || !scrollElems[pos-1]
				) &&
				scrollElemToWatch.className.indexOf("started") === -1 && ifElementInView(scrollElemToWatch, 50)
			) {
                if(scrollElemToWatch.className.indexOf("in_view") === -1) {
                    scrollElemToWatch.className += " in_view";
                }

                if (window.innerWidth > 767 && scrollElems[pos - 1] && scrollElems[pos - 1].className.indexOf('finished') === -1 && scrollElems[pos - 1].className.indexOf('in_view') !== -1) {
                    var interval = setInterval(function () {
                        if (scrollElems[pos - 1].className.indexOf('finished') !== -1) {
                            clearInterval(interval);
                            scrollElemToWatch.className += " started";
                            animateBlockItems(scrollElemToWatch);
                        }
                    }, 100)
                } else {
                    if (scrollElemToWatch.className.indexOf('finished') === -1) {
                        scrollElemToWatch.className += " started";
                        animateBlockItems(scrollElemToWatch);
                    }
                }
            }
        };

        scrollElems.forEach(function(scrollElemToWatch,pos) {

            var position = 0;
            var watcher = scrollMonitor.create(scrollElemToWatch, position);

            watcher.enterViewport(function () {
                scrollListeners(scrollElemToWatch, pos);
            });

            window.addEventListener('scroll', function () {
                scrollListeners(scrollElemToWatch, pos);
            });
        });
    };

    const createCircleBlockAnimation = function () {
    	// only "How it works" page

        var className = "circle-animation-block";
        var scrollElems = Array.from(document.querySelectorAll("." + className));

        var animateFirstItem = function(block){
            var item = block.querySelector('.part_1');
            if(item && item.className.indexOf('visible') === -1){
                item.className+=" visible";
                setTimeout(function () {
                    animateSecondItem(block);
                }, 300);
            }else{
                animateSecondItem(block);
            }
        };

        var animateSecondItem = function (block) {
            if(block === scrollElems[0]){
                animateBlockItems(scrollElems[1]);
            }
            animateThirdItem(block);
        };

        var animateThirdItem = function (block) {
            var item = block.querySelector('.part_3');
            if(item && item.className.indexOf('visible') === -1){
                item.className+=" visible";
                setTimeout(function () {
                    animateFourthItem(block);
                }, 100);
            }else{
                animateFourthItem(block);
            }
        };

        var animateFourthItem = function (block) {
            var item = block.querySelector('.part_4');
            if(item && item.className.indexOf('visible') === -1){
                item.className+=" visible";
                setTimeout(function () {
                    animateFifthItem(block);
                }, 100);
            }else{
                animateFifthItem(block);
            }
        };

        var animateFifthItem = function (block) {
            var item = block.querySelector('.part_5');
            if(item && item.className.indexOf('visible') === -1){
                item.className+=" visible";
                setTimeout(function () {
                    animateSixthItem(block);
                }, 100);
            }else{
                animateSixthItem(block);
            }
        };

        var animateSixthItem = function (block) {
            var item = block.querySelector('.part_6');
            if(item && item.className.indexOf('visible') === -1){
                item.className+=" visible";
            }else{
                animateLine(block);
            }
        };

        var animateLine = function (block) {
            var line = document.querySelector(block.dataset.line);
            if(!block || !block.dataset.stroke || !line || !line.style.strokeDashoffset || window.innerWidth < 768){
                block.className+=" finished";
                scrollElems.forEach(function(scrollElemToWatch,pos) {
                    if(block === scrollElemToWatch && scrollElems[pos+1]){
                        scrollListeners(scrollElems[pos+1], pos+1);
                    }
                });

                return false;
            }
        };

        var animateBlockItems = function(block){
            animateFirstItem(block);
        };

        var scrollListeners = function(scrollElemToWatch, pos){
            if(((scrollElems[pos-1] && scrollElems[pos-1].className.indexOf('finished') !== -1) || !scrollElems[pos-1]) && scrollElemToWatch.className.indexOf("started") === -1 && ifElementInView(scrollElemToWatch, 50)) {
                if(scrollElemToWatch.className.indexOf("in_view") === -1) {
                    scrollElemToWatch.className += " in_view";
                }

                if (window.innerWidth > 767 && scrollElems[pos - 1] && scrollElems[pos - 1].className.indexOf('in_view') !== -1) {
                    if (scrollElems[pos - 1].className.indexOf('finished') !== -1 && scrollElemToWatch.className.indexOf('can_start') !== -1) {
                        scrollElemToWatch.className += " started";
                        animateBlockItems(scrollElemToWatch);
                    }
                } else {
                    if (scrollElemToWatch.className.indexOf('finished') === -1) {
                        scrollElemToWatch.className += " started";
                        animateBlockItems(scrollElemToWatch);
                    }
                }
            }
        };

        scrollElems.forEach(function(scrollElemToWatch,pos) {
            var position = 0;
            var watcher = scrollMonitor.create(scrollElemToWatch, position);

            watcher.enterViewport(function () {
                scrollListeners(scrollElemToWatch, pos);
            });

            window.addEventListener('scroll', function () {
                scrollListeners(scrollElemToWatch, pos);
            })
        });
    };

    const createLiveAnimation = function () {
        var className = "live-transform";
        var scrollElems = Array.from(document.querySelectorAll("." + className));

		scrollElems.forEach(function(scrollElemToWatch) {
			var isScrolling;
			var prevPos = 1000000;

			window.addEventListener('scroll', function () {
				// clear previously setted timeout
				clearTimeout(isScrolling);

				// do nothing if mobile device
				if(window.innerWidth <= 767){
					scrollElemToWatch.style.transform = "none";
					return false;
				}

				// add class to initiate transform when scrolling
				if(scrollElemToWatch.className.indexOf("is_scrolling") === -1) {
					scrollElemToWatch.className += " is_scrolling";
				}

				// get initial position
				var pos = getElementPosInView(scrollElemToWatch)/3;
				if(scrollElemToWatch.className.indexOf("to_bottom") !== -1) {
					// vice versa position if element has class to bottom
					pos = 0 - pos;
				}

				scrollElemToWatch.style.transform = "translate3d(0px, " + pos + "px, 0px)";

				var step = 0;

				// increase value in pixels for interval step
				var i = 0.03;

				// if pos is negative
				if ( prevPos > pos ){
					i = -0.03;
				}

				// save initial position
				prevPos = pos;

				// set scrollig timeout
				isScrolling = setTimeout(function () {
					// remove class to prevent duplicate timeouts
					scrollElemToWatch.className = scrollElemToWatch.className.replace(" is_scrolling", "");

					var interval = setInterval(function () {
						if(step >= 100 || scrollElemToWatch.className.indexOf("is_scrolling") !== -1){
							clearInterval(interval);
							return false;
						}


						step++;
						pos += i;

						scrollElemToWatch.style.transform = "translate3d(0px, " + pos + "px, 0px)";

					}, 10);

				},50);

			});
		});
    };

    const init = function() {
        //imagesLoaded(document.body, function(){
        createScrollWatchers();
        createCircleBlockAnimation();
        initShapeLoop();
        createLiveAnimation();
        //});
    };

    init();
}

$(document).ready(function() {
    var className = "circle-animation-block";
    var scrollElems = Array.from(document.querySelectorAll("." + className));

    $(window).scroll(function() {
        drawLine($(".line-placeholder .line_1 path"));
        drawGraphLine($(".graph_line path"));
    });

    // init the line length
    drawLine($(".line-placeholder .line_1 path"));
    drawGraphLine($(".graph_line path"));

    //draw the line
    function drawLine(paths) {
        for (var i = 0; i < paths.length; i++) {
            var pathLength = paths[i].getTotalLength(),
                maxScrollTop = $(".line-placeholder").height() - $(window).height(),
                percentDone = ($(window).scrollTop()) / maxScrollTop,
                length = percentDone * pathLength * 1.2;
            if(length >= 470 && scrollElems[2] && scrollElems[2].className.indexOf('can_start') === -1){
                scrollElems[2].className += ' can_start';
            }
            if(length >= 830 && scrollElems[3] && scrollElems[3].className.indexOf('can_start') === -1){
                scrollElems[3].className += ' can_start';
            }
            paths[i].style.strokeDasharray = [length, pathLength].join(" ");
        }
    }

    function drawGraphLine(paths) {
        for (var i = 0; i < paths.length; i++) {
            var pathLength = paths[i].getTotalLength(),
                elemPos = paths[i].getBoundingClientRect(),
                maxScrollTop = $(window).height(),
                percentDone = ((maxScrollTop - elemPos.y - elemPos.height/2 ) / (maxScrollTop - 100)),
                length = percentDone * pathLength *1.2;
            if(!length){
                length = pathLength;
            }

            paths[i].style.strokeDasharray = [length, pathLength].join(" ");
        }
    }
});
